import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { 
    Card, 
    Chip, 
    CardContent, 
    Typography,
    Tooltip,
    IconButton, 
    Box 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
      height: '100%',
      minHeight: 300,
      width: '100%',
    },
  title: {
    marginBottom: theme.spacing(2),
  },
  chartContainer: {
    height: 250,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  legendDot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
  wipChip: {
    backgroundColor: '#536dfe',
    color: theme.palette.common.white,
    height: 24,
    marginRight: theme.spacing(2),
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  enabledTooltip: {
    fontSize: '0.65rem',
    backgroundColor: '#536dfe',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    maxWidth: '220px',
    lineHeight: '1.4em',
    letterSpacing: '0.15px',
  },
}));

const COLORS = ['#e040fb', '#673ab7'];

export const ConsultantStats = () => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [stats, setStats] = React.useState({ active: 0, bench: 0 });

  React.useEffect(() => {
    const fetchStats = async () => {
      const consultants = await catalogApi.getEntities({
        filter: {
          kind: 'User',
        },
      });

      const active = consultants.items.filter(
        consultant => consultant.metadata.annotations?.['monday.com/active'] === 'true' || false,
      ).length;

      setStats({
        active,
        bench: consultants.items.length - active,
      });
    };

    fetchStats();
    const interval = setInterval(fetchStats, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, [catalogApi]);

  const data = [
    { name: 'Active', value: stats.active },
    { name: 'Bench', value: stats.bench },
  ];

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.titleContainer}>
          <Chip 
            label="Work in Progress" 
            size="small" 
            className={classes.wipChip}
          />
          <Typography variant="h6">
            Consultant Activity
          </Typography>
          <Tooltip 
            title="Shows the current ratio between active consultants and those available for new projects"
            placement="top"
            classes={{ tooltip: classes.enabledTooltip }}
          >
            <IconButton size="small">
              <InfoOutlinedIcon className={classes.infoIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <Box className={classes.chartContainer}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <div className={classes.legend}>
          {data.map((entry, index) => (
            <div key={entry.name} className={classes.legendItem}>
              <div
                className={classes.legendDot}
                style={{ backgroundColor: COLORS[index] }}
              />
              <Typography>
                {entry.name}: {entry.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
