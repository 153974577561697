import { ConfigApi } from '@backstage/core-plugin-api';
import { FeatureRequestApi } from './types';

import { createAppAuth } from '@octokit/auth-app';

export class FeatureRequestClient implements FeatureRequestApi {
  private readonly config: ConfigApi;

  constructor(options: { config: ConfigApi }) {
    this.config = options.config;
  }

  private async getInstallationId(): Promise<string> {
    const appId = this.config.getString('featureRequest.github.appId');
    const privateKey = this.config.getString('featureRequest.github.privateKey');
    const clientId = this.config.getString('featureRequest.github.clientId');
    const clientSecret = this.config.getString('featureRequest.github.clientSecret');
    
    const auth = createAppAuth({
      appId,
      privateKey,
      clientId,
      clientSecret,
    });

    // Get JWT token first
    const { token: jwtToken } = await auth({ type: "app" });

    // Get installation ID using JWT
    const response = await fetch('https://api.github.com/app/installations', {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'Accept': 'application/vnd.github+json',
        'X-GitHub-Api-Version': '2022-11-28',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get installation ID');
    }

    const installations = await response.json();
    return installations[0].id;
  }

  private async getInstallationToken(): Promise<string> {
    const appId = this.config.getString('featureRequest.github.appId');
    const privateKey = this.config.getString('featureRequest.github.privateKey');
    const installationId = await this.getInstallationId();

    const auth = createAppAuth({
      appId,
      privateKey,
      installationId,
    });

    const { token } = await auth({ type: "installation" });
    return token;
  }

  async createDraftIssue(request: { title: string; description: string }): Promise<void> {
    const owner = this.config.getOptionalString('featureRequest.github.owner');
    const repo = this.config.getOptionalString('featureRequest.github.repo');

    const token = await this.getInstallationToken();
  
    const response = await fetch(`https://api.github.com/repos/${owner}/${repo}/issues`, {
      method: 'POST',
      headers: {
        'Authorization': `token ${token}`,
        'Accept': 'application/vnd.github+json',
        'Content-Type': 'application/json',
        'X-GitHub-Api-Version': '2022-11-28',
      },
      body: JSON.stringify({
        title: request.title,
        body: request.description,
        labels: [ 'feature-request'],
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to create issue: ${response.status} ${response.statusText} - ${errorBody}`);
    }
  }
}
