import { createApiRef } from '@backstage/core-plugin-api';

export interface SharePointFile {
    name: string;
    path: string;
    size: number;
    modifiedAt: string;
    contentType: string;
}

export interface SharePointApi {
    listPdfFiles(path: string): Promise<SharePointFile[]>;
    getDocument(path: string): Promise<ArrayBuffer>;
}

export const sharePointServiceApiRef = createApiRef<SharePointApi>({
    id: 'plugin.sharepoint.api',
});
