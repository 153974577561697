// Base imports to build the home page
import {   
    TemplateBackstageLogoIcon,  
} from '@backstage/plugin-home';
import { useNavigate } from 'react-router-dom';
import { Content, Page } from '@backstage/core-components';
import { useApi, identityApiRef, useRouteRef } from '@backstage/core-plugin-api';
import { 
  Grid, 
  Fab, 
  makeStyles,
  Paper, 
  Typography, 
  Tooltip,
  Dialog, 
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
// Material UI icons
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import SlackIcon from '@material-ui/icons/Chat';
import GitHubIcon from '@material-ui/icons/GitHub';
// Import latest announcements banner component
import { AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
import { ConsultantStats } from './utils/ConsultantStats';
import { BudgetStats } from './utils/BudgetStats';

import { FeatureRequestPage, rootRouteRef as featureRequestRouteRef } from '@internal/backstage-plugin-feature-request';

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
  },
  searchBarInput: {
    maxWidth: '60vw',
    width: '600px',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  logoSection: {
    marginBottom: theme.spacing(4),
  },
  announcementsSection: {
    marginTop: theme.spacing(4),
    maxWidth: '800px',
    margin: '0 auto',
  },
  greetingContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  greeting: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  welcomeMessage: {
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    fontWeight: 300,
    animation: '$fadeIn 0.5s ease-in',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(-10px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },
  buttonContainer: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
      bottom: `calc(${theme.spacing(2)}px + 56px + env(safe-area-inset-bottom))`, // 56px is nav height
      gap: theme.spacing(1),
    },
  },
  githubButton: {
    backgroundColor: '#4A154B',
    '&:hover': {
      backgroundColor: '#611f69',
    },
    width: 56,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  slackButton: {
    backgroundColor: '#4A154B',
    '&:hover': {
      backgroundColor: '#611f69',
    },
    width: 56,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  slackIcon: {
    color: theme.palette.common.white,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  buttonIcon: {
    color: theme.palette.common.white,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  enabledTooltip: {
    fontSize: '0.875rem',
    backgroundColor: '#9C27B0',
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    maxWidth: '220px',
    lineHeight: '1.4em',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tooltip: {
    fontSize: '0.875rem',
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    maxWidth: '220px',
    lineHeight: '1.4em',
    letterSpacing: '0.15px',
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(2),
    },
    fontWeight: 510,
    textAlign: 'center',
    fontSize: '1.85rem',
  },
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    width: '100%',
    margin: '0',
    boxSizing: 'border-box',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0',
    padding: '100',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
})); 
  
const useBannerStyles = makeStyles(theme => ({  
  container: {  
    backgroundColor: theme.palette.warning.light,  
    color: theme.palette.warning.contrastText,  
    padding: theme.spacing(2),  
    display: 'flex',  
    alignItems: 'center',  
    justifyContent: 'center',  
    borderRadius: 5,  
    marginTop: theme.spacing(3),  
  },  
  icon: {  
    marginRight: theme.spacing(1),  
  },  
}));  
  
// Banner
const WarningBanner: React.FC = () => {  
  const classes = useBannerStyles();  
  return (  
    <div className={classes.container}>  
      <WarningIcon className={classes.icon} />  
      This is an example of a warning banner, you can give general advice here  
    </div>  
  );  
};
  
export const HomePage = () => {  
  const classes = useStyles();  
  const identity = useApi(identityApiRef);
  const [displayName, setDisplayName] = React.useState<string>('');
  
  const featureRequestRoute = useRouteRef(featureRequestRouteRef);
  const navigate = useNavigate();
  
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  React.useEffect(() => {
    const fetchIdentity = async () => {
      const profile = await identity.getProfileInfo();
      setDisplayName(profile.displayName || 'Guest');
    };
    fetchIdentity();
  }, [identity]);
  
  const getTimeGreeting = (): string => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good Morning';
    if (hour < 18) return 'Good Afternoon';
    return 'Good Evening';
  };
  
  const getPersonalizedGreeting = () => {
    const greeting = getTimeGreeting();
    return displayName ? `${greeting}, ${displayName}!` : `${greeting}!`;
  };

  // Slack button click handler
  const handleSlackClick = () => {
    // Links to #xt-psde-ibp Slack channel
    window.open('https://xebia.slack.com/archives/C081UPH1W9X', '_blank');
  };

  // Direct links to useful locations, which you can change to whatever you want
  const tools = [  
    {  
      url: '/create/actions',  
      label: 'Create/Actions',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/docs',  
      label: 'Docs',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/catalog?filters%5Bkind%5D=user',  
      label: 'User Catalog',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/catalog?filters%5Bkind%5D=group',  
      label: 'Group Catalog',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
  ];  
  
  // Use the search bar and starred entities as is
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid 
            container 
            direction="column"
            alignItems="center"
            className={classes.container}
          >
            <Grid item className={classes.greetingContainer}>
              <Typography variant="h2" className={classes.greeting}>
                {getPersonalizedGreeting()}
              </Typography>
              <Typography variant="body1" className={classes.welcomeMessage}>
                We're glad to have you here.
              </Typography>
            </Grid>
            <Grid item>
              <HomePageSearchBar
                InputProps={{
                  classes: { 
                    root: classes.searchBarInput, 
                    notchedOutline: classes.searchBarOutline 
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid item className={classes.announcementsSection}>
              <AnnouncementsCard />
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              <Paper className={classes.root}>
                <Typography variant="h4" className={classes.title}>
                  At a glance...
                </Typography>
                <Grid 
                  container 
                  spacing={2} 
                  className={classes.statsContainer}
                >
                  <Grid 
                    item 
                    xs={12} 
                    md={6} 
                  >
                    <ConsultantStats />
                  </Grid>
                  <Grid 
                    item 
                    xs={12} 
                    md={6} 
                  >
                    <BudgetStats />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Content>
        <div className={classes.buttonContainer}>
          <Tooltip 
            title="Submit a feature request" 
            placement="top"
            classes={{ tooltip: classes.enabledTooltip }}
          >
            <span>
              <Fab
                className={classes.githubButton}
                onClick={handleOpenModal}
                aria-label="Request Feature"
              >
                <GitHubIcon className={classes.buttonIcon} />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip 
            title="Have questions or want to contribute? Chat with us on Slack!" 
            placement="top"
            classes={{ tooltip: classes.enabledTooltip }}
          >
            <Fab
              className={classes.slackButton}
              onClick={handleSlackClick}
              aria-label="Join Slack Channel"
            >
              <SlackIcon className={classes.buttonIcon} />
            </Fab>
          </Tooltip>
        </div>
      </Page>
      <Dialog 
          open={isModalOpen} 
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle className={classes.dialogTitle}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FeatureRequestPage />
          </DialogContent>
        </Dialog>
    </SearchContextProvider>
  );
};  
