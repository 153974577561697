import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { 
    Tooltip, 
    IconButton,
    Chip,
    Card, 
    CardContent, 
    Typography, 
    Box, 
    CircularProgress,
 } from '@material-ui/core';

import { 
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip as RechartsTooltip, 
    Legend, 
    ResponsiveContainer 
  } from 'recharts';

const useStyles = makeStyles(theme => ({
  card: {
      height: '100%',
      minHeight: 300,
      width: '100%',
    },
  title: {
    marginBottom: theme.spacing(2),
  },
  chartContainer: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing(2),
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  legendDot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
  totals: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  wipChip: {
    backgroundColor: '#536dfe',
    color: theme.palette.common.white,
    height: 24,
    marginRight: theme.spacing(2),
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    fontSize: '1rem',
  },
  enabledTooltip: {
    fontSize: '0.65rem',
    backgroundColor: '#536dfe',
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    maxWidth: '220px',
    lineHeight: '1.4em',
    letterSpacing: '0.15px',
  },
}));

export const BudgetStats = () => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [stats, setStats] = React.useState({ totalBudget: 0, totalYield: 0 });
  const [loading, setLoading] = React.useState(true);

  const parseBudgetValue = (value: string | undefined): number => {
    if (!value) return 0;
  
    // Clean and parse the value
    const cleanValue = value.replace(/,/g, '');
    const parsed = Number(cleanValue);
    
    // Handle NaN and invalid values
    if (isNaN(parsed)) {
      console.warn('Invalid budget value:', { value, cleanValue });
      return 0;
    }
  
    return parsed;
  };

  React.useEffect(() => {
    const fetchStats = async () => {
      try {
        const engagements = await catalogApi.getEntities({
          filter: { kind: 'Component' },
        });

        const totalBudget = engagements.items.reduce((sum, engagement) => {
            const budgetStr = engagement.metadata.annotations?.['monday.com.field-budget'];
            const budget = parseBudgetValue(budgetStr);
            return sum + budget;
          }, 0);

        const totalYield = engagements.items.reduce((sum, engagement) => {
          const yieldStr = engagement.metadata.annotations?.['monday.com.field-yield'];
          const revenue = parseBudgetValue(yieldStr) || 0;
          return sum + revenue;
        }, 0);
        setStats({ totalBudget, totalYield });
      } catch (error) {
        console.error('Failed to fetch budget stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 30000);
    return () => clearInterval(interval);
  }, [catalogApi]);

  if (loading) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.loading}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  const data = [
    {
      name: 'Budget vs. Yield',
      Budget: stats.totalBudget,
      Yield: stats.totalYield,
    },
  ];

  const percentage = ((stats.totalYield / stats.totalBudget) * 100).toFixed(1);
  
  return (
    <Card className={classes.card}>
      <CardContent>
      <div className={classes.titleContainer}>
          <Chip 
            label="Work in Progress" 
            size="small" 
            className={classes.wipChip}
          />
          <Typography variant="h6">
            Budget Overview
          </Typography>
          <Tooltip 
            title="Displays total budget allocation versus actual yield across all engagements"
            placement="top"
            classes={{ tooltip: classes.enabledTooltip }}
          >
            <IconButton size="small">
              <InfoOutlinedIcon className={classes.infoIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <Box className={classes.chartContainer}>
          <ResponsiveContainer>
            <BarChart 
              layout="vertical" 
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              barGap={8}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                type="number" 
                hide={true}
              />
              <YAxis 
                type="category" 
                dataKey="name" 
                width={100}
                axisLine={false}
                tickLine={false}
              />
              <RechartsTooltip 
                formatter={(value) => `€${value.toLocaleString()}`}
              />
              <Legend />
              <Bar 
                dataKey="Budget" 
                fill="#673ab7"
                barSize={30}
              />
              <Bar 
                dataKey="Yield" 
                fill="#e040fb" 
                barSize={30}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Typography variant="body2" className={classes.totals}>
          Yield percentage: {percentage}%
        </Typography>
      </CardContent>
    </Card>
  );
};
