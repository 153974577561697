import React from 'react';
import {
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
  createRoutableExtension,
  configApiRef, 
  useApi
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { sharePointServiceApiRef, SharePointClient } from './api';
import { SharePointExplorerProps } from './components/SharePointExplorer/types';

export const sharepointFrontendPlugin = createPlugin({
  id: 'sharepoint-frontend',
  apis: [
    createApiFactory({
      api: sharePointServiceApiRef,
      deps: { 
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) => 
        new SharePointClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export type SharepointFrontendPageProps = SharePointExplorerProps;

export const SharepointFrontendPage = sharepointFrontendPlugin.provide(
  createRoutableExtension({
    name: 'SharepointFrontendPage',
    component: () =>
      import('./components/SharePointExplorer').then(
        m => function SharePointExplorerWrapper(props: SharepointFrontendPageProps) {
          return React.createElement(m.SharePointExplorer, props);
        }
      ),
    mountPoint: rootRouteRef,
  }),
);
