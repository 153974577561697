import { 
  DiscoveryApi, 
  IdentityApi,
} from '@backstage/core-plugin-api';
import { SharePointFile, SharePointApi } from './types';

export class SharePointClient implements SharePointApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private tokenPromise: Promise<string> | null = null;

  constructor(options: { 
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async refreshToken(): Promise<void> {
    this.tokenPromise = null; // Clear cached token
    await this.getToken(); // Force new token fetch
  }

  private async getToken(): Promise<string> {
    if (!this.tokenPromise) {
      this.tokenPromise = this.fetchNewToken();
    }
    return this.tokenPromise;
  }

  private async fetchNewToken(): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('sharepoint');
    const response = await fetch(`${baseUrl}/refresh-token`);
    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }
    return response.text();
  }

  private async getHeaders(): Promise<Headers> {
    const { token } = await this.identityApi.getCredentials();
    return new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }

  async listPdfFiles(path: string): Promise<SharePointFile[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('sharepoint');
    const headers = await this.getHeaders();

    const response = await fetch(`${baseUrl}/files/${encodeURIComponent(path)}`, {
      headers,
    });
    if (!response.ok) throw new Error('Failed to list files');
    return response.json();
  }

  async getDocument(path: string): Promise<ArrayBuffer> {
    const baseUrl = await this.discoveryApi.getBaseUrl('sharepoint');
    const headers = await this.getHeaders();

    const response = await fetch(`${baseUrl}/document/${encodeURIComponent(path)}`, {
      headers,
    });
    if (!response.ok) throw new Error('Failed to fetch document');
    return response.arrayBuffer();
  }
}
