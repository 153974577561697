import React from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  List, 
  ListItem, 
  makeStyles,
  Chip,
  Typography,
} from '@material-ui/core';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles(theme => ({
    card: {
      height: '100%',
      boxShadow: theme.shadows[2],
    },
    listItem: {
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    entityLink: {
      color: '#343b58',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      flexGrow: 1,
    },
    status: {
      marginLeft: theme.spacing(1),
      borderRadius: '16px',
      minWidth: '80px',
      justifyContent: 'center',
    },
    active: {
      backgroundColor: '#27ae60',
      color: 'white',
    },
    inactive: {
      backgroundColor: '#e74c3c',
      color: 'white',
    },
    listContent: {
      padding: theme.spacing(1),
    },
}));

const isEngagementActive = (engagement: any): boolean => {
    const startDate = engagement.metadata.annotations?.['monday.com.field-dateStart'];
    const endDate = engagement.metadata.annotations?.['monday.com.field-dateEnd'];
    
    if (!startDate || !endDate) return false;
    
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    return now >= start && now <= end;
};

export const ConsultantEngagementsPanel = ({ consultantName }: { consultantName?: string }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [engagements, setEngagements] = React.useState<any[]>([]);

  React.useEffect(() => {
    const fetchEngagements = async () => {
      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'Resource',
          'spec.type': 'engagement',
        },
      });
      
      const filtered = items.filter(item => {
        const consultants = item.metadata.annotations?.['monday.com.field-consultantName'] || '';
        return consultants.includes(consultantName || '');
      });
      
      setEngagements(filtered);
    };

    if (consultantName) {
      fetchEngagements();
    }
  }, [catalogApi, consultantName]);

  return (
    <Card className={classes.card}>
      <CardHeader 
        title="Engagements"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <List className={classes.listContent}>
          {engagements.map(engagement => (
            <ListItem 
              key={engagement.metadata.name}
              className={classes.listItem}
              divider
            >
              <EntityRefLink 
                entityRef={engagement}
                className={classes.entityLink}
              />
              <Chip 
                size="small"
                label={isEngagementActive(engagement) ? 'Active' : 'Inactive'}
                className={`${classes.status} ${
                  isEngagementActive(engagement) ? classes.active : classes.inactive
                }`}
              />
            </ListItem>
          ))}
          {engagements.length === 0 && (
            <Typography color="textSecondary" align="center">
              No engagements found
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
};
