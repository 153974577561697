import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  configApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { featureRequestApiRef } from './api/FeatureRequestApiRef';
import { FeatureRequestClient } from './api/FeatureRequestClient';

export const featureRequestPlugin = createPlugin({
  id: 'feature-request',
  apis: [
    createApiFactory({
      api: featureRequestApiRef,
      deps: { config: configApiRef },
      factory: ({ config }) => new FeatureRequestClient({ config }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const FeatureRequestPage = featureRequestPlugin.provide(
  createRoutableExtension({
    name: 'FeatureRequestPage',
    component: () =>
      import('./components/FeatureRequestPage').then(m => m.FeatureRequestPage as () => JSX.Element),
    mountPoint: rootRouteRef,
  }),
);
