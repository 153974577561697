import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const skillsFrontendPlugin = createPlugin({
  id: 'skills-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const SkillsFrontendPage = skillsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'SkillsFrontendPage',
    component: () =>
      import('./components/SkillsForm').then(m => m.SkillsForm),
    mountPoint: rootRouteRef,
  }),
);
